<template>
  <KTCodePreview v-bind:title="'In phiếu'">
    <template v-slot:toolbar>
      <b-button
        variant="primary"
        size="sm"
        @click="printData"
        style="width: max-content"
      >
        <i class="fas fa-print"></i>
        In phiếu
      </b-button>
    </template>
    <template v-slot:preview>
      <div id="printMe"  style="margin: 0 40px">
        <div>
            <b-row style="display: block" class="mb-10">
              <div class="text-center">
                <h5 style="font-size: 21px">PHIẾU THU CŨ</h5>
                <p>Ngày {{ getCurrentDate }}</p>
              </div>
            </b-row>
            <p>
              <span class="custom-lable-text font-weight-bold">Họ và tên khách hàng (người bán)</span>
              : <span class="text-center">{{ customerInfo.providerName }}</span> 
            </p>
            <p>
              <span class="custom-lable-text font-weight-bold">Số điện thoại</span>
              : {{ customerInfo.customerPhone }}
            </p>
            <p>
              <span class="custom-lable-text font-weight-bold">CMND/CCCD số</span>
              : {{ customerInfo.customerIdentityNo }}
            </p>
            <p>
              <span class="custom-lable-text font-weight-bold">Địa chỉ liên hệ</span>
              : {{ customerInfo.address }}
            </p>
            <p>
              <span class="custom-lable-text font-weight-bold">
                Căn cứ theo chính sách ĐỔI TRẢ tại Hệ thống Airtrading. Căn cứ theo Phiếu định giá sản phẩm số 
                {{ customerInfo.relatedStockCode }} ngày {{ customerInfo.createdAt }}
              </span>
            </p>
            <p>
              <span class="custom-lable-text font-weight-bold">
                Hình thức thanh toán : 
              </span>
              <span v-if="(customerInfo.cashAmount > 0)">Tiền mặt</span>
              <span v-if="(customerInfo.transferAmount > 0)">, chuyển khoản</span>
            </p>
            <p>
              <span class="custom-lable-text font-weight-bold">
                Nhập tại kho
              </span>
              : {{ customerInfo.storeName }}
            </p>
            <b-table
              :items="customerInfo.listDetail"
              :fields="fields"
              :bordered="true"
              :hover="true"
            >
              <template slot="bottom-row">
                <td
                  v-bind:colspan="3"
                  class="text-right font-weight-bolder"
                  style="font-weight: 600,color: '#181c32'"
                >
                  <span>Tổng:</span>
                </td>
                <td class="text-right font-weight-bolder">
                  <span>{{ formatMoney(calculateTotal('quantity')) }}</span>
                </td>
                <td colspan="2" class="text-right font-weight-bolder">
                  <span>{{ formatMoney(calculateTotal('totalAmount')) }}</span>
                </td>
              </template>
            </b-table>
            <b-row>
              <b-col>
                <p class=" font-weight-bold">
                  <span class="custom-lable-text"
                    >Tổng số tiền (viết bằng chữ)</span
                  >
                  : {{ convertNumberToText(calculateTotal('totalAmount')) }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-center">
                <p class="mb-0">
                  <span class="custom-lable-text font-weight-bold font-italic">Người lập phiếu</span>
                </p>
                <p class="font-italic">(Ký, họ tên)</p>
              </b-col>
              <b-col class="text-center">
                <p class="mb-0">
                  <span class="custom-lable-text font-weight-bold font-italic">Người bán</span>
                </p>
                <p class="font-italic">(Ký, họ tên)</p>
              </b-col>
              <b-col class="text-center">
                <p class="mb-0">
                  <span class="custom-lable-text font-weight-bold font-italic">Thủ kho</span>
                </p>
                <p class="font-italic">(Ký, họ tên)</p>
              </b-col>
              <b-col class="text-center">
                <p class="mb-0">
                  <span class="custom-lable-text font-weight-bold font-italic"> Ngày {{ getCurrentDate }}</span>
                </p>
                <p class="font-weight-bold mb-0">Quản lý của hàng</p>
                <p class="font-italic">(Ký, họ tên)</p>
              </b-col>
            </b-row>
        </div>
      </div>
    </template>
  </KTCodePreview>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertNumberToText } from '@/utils/common';
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { TIME_TRIGGER } from '@/utils/constants';
import _ from 'lodash';
import { makeToastFaile } from '@/utils/common';

export default {
  data() {
    return {
      id: this.$route.query.id,
      customerInfo: { 
          providerName: '',
          providerId: '',
          customerPhone: '',
          address: '',
          customerIdentityNo: '',
          description: '',
          storeName: '',
          storeAddress: '',
          relatedStockCode: '',
          createdAt: '',
          cashAmount: 0,
          transferAmount: 0,
          listDetail: [],
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          thClass: 'countCollumnClass',
          tdClass: 'text-center',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thClass: 'productNameCollumnClass',
        },
        {
          key: 'productImei',
          label: 'IMEI',
          thClass: 'productImeiCollumnClass',
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thClass: 'quantityCollumnClass',
          tdClass: 'text-right',
          formatter: (value) => {
              return this.formatMoney(value);
          },
        },
        {
          key: 'unitPrice',
          label: 'Giá',
          sortable: false,
          thClass: 'unitPriceCollumnClass',
          tdClass: 'text-right',
          formatter: (value) => {
              return this.formatMoney(value);
          },
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thClass: 'totalAmountCollumnClass',
          tdClass: 'text-right',
          formatter: (value) => {
              return this.formatMoney(value);
          },
        },
      ],
    }
  },
  components:{ 
    KTCodePreview,
  },
  mounted(){
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu thu cũ', route: '/import-trade-in' },
      { title: 'In phiếu thu cũ' },
    ]);
    this.fetchImportTradeInById();
  },
  computed: {
    getCurrentDate(){ 
      return moment().lang('vi').format('LL');
    },
    calculateTotal(){ 
      return attribute => { 
          let total = 0;
          _.map(this.customerInfo.listDetail, item => { 
              total += item[attribute]
          });
          return total;
      }
    }
  },
  methods: {
    convertNumberToText,
    printData(){
      const prtHtml = document.getElementById('printMe').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1280,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}

          <style>
            @media print {
              body {  
                font-size: 21px !important;
              };
            }
          </style>
        </head>
        <body`);
      WinPrint.document.write(`${prtHtml}

        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function() {
        WinPrint.print();
      }, TIME_TRIGGER); 
    },
    formatMoney(number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchImportTradeInById(){
      ApiService.get(`stocks/${this.id}`).then(({ data }) => {
        if (data.status === 1){ 
          const responseData = data.data; 
          this.customerInfo.providerName = responseData.providerName;
          this.customerInfo.providerId = responseData.providerId;
          this.customerInfo.customerPhone = responseData.customerPhone;
          this.customerInfo.address = responseData.address;
          this.customerInfo.customerIdentityNo = responseData.customerIdentityNo;
          this.customerInfo.description = responseData.description;
          this.customerInfo.storeName = responseData.storeName;
          this.customerInfo.storeAddress = responseData.storeAddress;
          this.customerInfo.relatedStockCode = responseData.relatedStockCode;
          this.customerInfo.createdAt = moment(responseData.createdAt).format('DD/MM/YYYY')
          this.customerInfo.cashAmount = responseData.cashAmount;
          this.customerInfo.transferAmount = responseData.transferAmount;
          responseData.listDetail.map((element,index)=>{ 
            const item = { 
                count: ++index,
                ...element,
            }
            this.customerInfo.listDetail.push(item);
          });
          setTimeout(() => {
            this.printData();
          }, TIME_TRIGGER);
        } else {
          makeToastFaile('Không thể lấy được thông tin phiếu thu cũ')
        }
      });
    },
  },
  watch: {
    $route(to){ 
      this.id = to.query.id;
    }
  },
}
</script>

<style>
  .countCollumnClass { 
    color: #181C32;
    text-align: center;
    font-weight: 600 !important;
    width: 1%;
    font-size: 15px !important;
  }

  .productNameCollumnClass { 
    color: #181C32;
    text-align: center;
    font-weight: 600 !important;
    width: 15%;
    font-size: 15px !important;
  }

  .productImeiCollumnClass { 
    color: #181C32;
    text-align: center;
    font-weight: 600 !important;
    width: 10%;
    font-size: 15px !important;
  }

  .quantityCollumnClass { 
    color: #181C32;
    text-align: center;
    font-weight: 600 !important;
    width: 1%;
    font-size: 15px !important;
  }

  .unitPriceCollumnClass { 
    color: #181C32;
    text-align: center;
    font-weight: 600 !important;
    width: 10%;
    font-size: 15px !important;
  }

  .totalAmountCollumnClass { 
    color: #181C32;
    text-align: center;
    font-weight: 600 !important;
    width: 12%;
    font-size: 15px !important;
  }
</style>